import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ContentContainer from '../Layout/ContentContainer'

const TeamPhoto = () => (
  <ContentContainer className='translate-y-12 relative z-10'>
    <StaticImage
      src='../../../images/careers/teamPhoto.jpg'
      alt='team'
      className='rounded-xl'
      placeholder='blurred'
      layout='fullWidth'
    />
  </ContentContainer>
)

export default TeamPhoto
