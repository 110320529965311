import React from 'react'
import SectionHeader from '../../common/Header/SectionHeader'
import ContentContainer from '../../common/Layout/ContentContainer'
import TgcLink from '../../common/TgcLink/TgcLink.jsx'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const query = graphql`
  query {
    backgroundImage: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "careers/careersHeader.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`

const CareersHeader = () => {
  const { backgroundImage } = useStaticQuery(query)
  const image = getImage(backgroundImage)

  return (
    <div className='relative h-max text-brand-secondary'>
      <GatsbyImage
        image={image}
        alt='people working together'
        className='absolute inset-0 w-full h-full'
        loading='eager'
        role='presentation'
      />
      <div className='relative pt-20 pb-20'>
        <SectionHeader wordsToType={['Careers']} />
        <ContentContainer>
          <p className='text-4xl leading-snug font-light lg:text-6xl'>
            We make the recruitment process with the.good.code; easy and
            transparent to ensure that each candidate is well-informed and
            prepared for the steps to come.
          </p>
          <div className='my-20'>
            <TgcLink href='https://zest-expansion-c6b.notion.site/Open-Positions-with-the-good-code-b83b97611e654d31a281f945660bb343'>
              Open Positions
            </TgcLink>
          </div>
        </ContentContainer>
      </div>
    </div>
  )
}

export default CareersHeader
